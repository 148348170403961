import {
  ButtonHTMLAttributes,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import LinkN, { LinkProps } from 'next/link';
import {
  IconArrowBottom,
  IconArrowDown,
  IconArrowRight,
  iconColorOptions,
  IconDots,
  IconSearch,
  IconTrash,
  IconUpload,
} from '../icons';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';

type ButtonWidth = 'normal' | 'medium' | 'large';
type ButtonSize = 'extraSmall' | 'small' | 'normal';
type ButtonIcon =
  | 'rightArrow'
  | 'search'
  | 'downChevron'
  | 'downArrow'
  | 'trash'
  | 'upload'
  | 'dots';
type ButtonPosition = 'left' | 'right';
type ButtonVerticalPosition = 'top' | 'center';
export type ButtonVariant =
  | 'default'
  | 'link'
  | 'linkAlt'
  | 'linkTiny'
  | 'icon'
  | 'outline'
  | 'outlineAlt'
  | 'outlineWhite';

interface IconProps {
  children: ReactNode;
  icon: ButtonIcon;
  iconColor: iconColorOptions;
  iconClassesFill: string;
  iconClassesStroke: string;
  iconPosition?: ButtonPosition;
  iconVerticalPosition?: ButtonVerticalPosition;
}

interface IconStyleProps extends Omit<IconProps, 'children' | 'icon'> {}

export const getButtonLoadingColor = (variant?: ButtonVariant) => {
  if (variant == 'icon') {
    return 'blue';
  } else if (variant == 'link') {
    return 'blue';
  } else if (variant == 'outline') {
    return 'white';
  } else if (variant == 'outlineAlt') {
    return 'white';
  } else if (variant == 'outlineWhite') {
    return 'blue';
  } else {
    return 'white';
  }
};

const ButtonIcon = ({
  children,
  icon,
  iconColor,
  iconClassesFill,
  iconClassesStroke,
  iconPosition = 'right',
  iconVerticalPosition = 'center',
}: IconProps) => {
  const isTopAlign = iconVerticalPosition === 'top';
  return (
    <div
      className={`flex ${
        !isTopAlign ? 'items-center' : ''
      } justify-center gap-3 ${
        iconPosition === 'left' ? 'flex-row-reverse' : ''
      }`}
    >
      <span className="">{children}</span>
      <div className={`shrink-0 ${isTopAlign ? 'mt-1.5' : ''}`}>
        {icon === 'rightArrow' && (
          <IconArrowRight
            data-testid="buttonicon-right-arrow"
            color={iconColor}
            className={iconClassesFill}
          />
        )}
        {icon === 'dots' && (
          <IconDots
            data-testid="buttonicon-dtos"
            color={iconColor}
            className={iconClassesFill}
          />
        )}
        {icon === 'search' && (
          <IconSearch
            data-testid="buttonicon-search"
            color={iconColor}
            className={iconClassesStroke}
          />
        )}
        {icon === 'downChevron' && (
          <IconArrowBottom
            data-testid="buttonicon-down-chevron"
            color={iconColor}
            className={iconClassesFill}
            size="S"
          />
        )}
        {icon === 'downArrow' && (
          <IconArrowDown
            data-testid="buttonicon-down-arrow"
            color={iconColor}
            className={`${iconClassesFill} ${isTopAlign ? 'mt-[1px]' : ''}`}
            size="S"
          />
        )}
        {icon === 'trash' && (
          <IconTrash
            data-testid="buttonicon-trash"
            color={iconColor}
            className={iconClassesStroke}
          />
        )}
        {icon === 'upload' && (
          <IconUpload
            data-testid="buttonicon-upload"
            color={iconColor}
            className={iconClassesFill}
          />
        )}
      </div>
    </div>
  );
};

type BaseProps = {
  children?: ReactNode;
  className?: string;
  variant?: ButtonVariant;
  width?: ButtonWidth;
  size?: ButtonSize;
  icon?: ButtonIcon;
  iconPosition?: ButtonPosition;
  iconVerticalPosition?: ButtonVerticalPosition;
  isLoading?: boolean;
  onIsHovering?: (isHovering: boolean) => void;
};

export type ButtonAsButtonProps = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
    as?: 'button';
  };

type ButtonAsLink = BaseProps &
  Omit<LinkProps, keyof BaseProps> & {
    as: 'link';
  };

export type ButtonProps = ButtonAsButtonProps | ButtonAsLink;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant,
      icon,
      className = '',
      width = 'normal',
      size = 'normal',
      isLoading = false,
      iconPosition = 'right',
      iconVerticalPosition = 'center',
      onIsHovering,
      ...props
    },
    ref
  ) => {
    if (isLoading) {
      (props as any)['data-loading'] = true;
    }
    const [isHovering, setIsHovering] = useState(false);
    const hoveringProps = {
      onMouseEnter: () => setIsHovering(true),
      onMouseLeave: () => setIsHovering(false),
    };
    useEffect(() => {
      onIsHovering && onIsHovering(isHovering);
    }, [isHovering, onIsHovering]);
    children = isLoading ? (
      <div className="relative inline">
        <div
          className={`absolute top-0 left-[-28px] bottom-0 my-auto w-[20px] h-[20px]`}
        >
          <LoadingSpinner color={getButtonLoadingColor(variant)} width={20} />
        </div>
        {children}
      </div>
    ) : (
      children
    );
    const widthClassMap: Record<ButtonWidth, string> = {
      normal: widthNormalClasses,
      medium: widthMediumClasses,
      large: widthLargeClasses,
    };
    const widthClasses = widthClassMap[width];
    const sizeClassMap: Record<ButtonSize, string> = {
      normal: sizeNormalClasses,
      small: sizeSmallClasses,
      extraSmall: sizeExtraSmallClasses,
    };
    const sizeClasses = sizeClassMap[size];
    let styleClasses = `${mainClasses} ${widthClasses} ${sizeClasses} ${className}`;
    let iconProps: IconStyleProps = {
      iconColor: 'nad-white',
      iconClassesFill: iconMainClassesFill,
      iconClassesStroke: iconMainClassesStroke,
    };
    if (variant == 'icon') {
      styleClasses = `${className} ${focusClasses} rounded-full transition disabled:cursor-not-allowed`;
    }
    if (variant == 'link') {
      styleClasses = `${linkClasses} ${widthClasses} ${sizeClasses} ${className}`;
      iconProps = {
        iconColor: 'nad-blue',
        iconClassesFill: iconLinkClassesFill,
        iconClassesStroke: iconLinkClassesStroke,
      };
    }
    if (variant == 'linkAlt') {
      styleClasses = `${linkAltClasses} ${widthClasses} ${sizeClasses} ${className}`;
      iconProps = {
        iconColor: 'nad-scarlett',
        iconClassesFill: iconLinkAltClassesFill,
        iconClassesStroke: iconLinkAltClassesStroke,
      };
    }
    if (variant == 'linkTiny') {
      styleClasses = `${linkTinyClasses} ${className}`;
    }
    if (variant == 'outline') {
      styleClasses = `${outlineClassName} ${widthClasses} ${sizeClasses} ${className}`;
      iconProps = {
        iconColor: 'nad-blue',
        iconClassesFill: iconOutlineClassesFill,
        iconClassesStroke: iconOutlineClassesStroke,
      };
    }
    if (variant == 'outlineAlt') {
      styleClasses = `${outlineAltClassName} ${widthClasses} ${sizeClasses} ${className}`;
      iconProps = {
        iconColor: 'nad-scarlett',
        iconClassesFill: iconOutlineAltClassesFill,
        iconClassesStroke: iconOutlineAltClassesStroke,
      };
    }
    if (variant == 'outlineWhite') {
      styleClasses = `${outlineWhiteClassName} ${widthClasses} ${sizeClasses} ${className}`;
      iconProps = {
        iconColor: 'nad-white',
        iconClassesFill: iconOutlineWhiteClassesFill,
        iconClassesStroke: iconOutlineWhiteClassesStroke,
      };
    }
    if (props.as === 'link') {
      const { as, ...rest } = props;
      return (
        <LinkN {...rest} className={styleClasses}>
          {icon && (
            <ButtonIcon
              icon={icon}
              iconPosition={iconPosition}
              iconVerticalPosition={iconVerticalPosition}
              {...iconProps}
            >
              {children}
            </ButtonIcon>
          )}
          {!icon && children}
        </LinkN>
      );
    } else {
      const { as, ...rest } = props;
      return (
        <button ref={ref} {...rest} {...hoveringProps} className={styleClasses}>
          {icon && (
            <ButtonIcon
              icon={icon}
              iconPosition={iconPosition}
              iconVerticalPosition={iconVerticalPosition}
              {...iconProps}
            >
              {children}
            </ButtonIcon>
          )}
          {!icon && children}
        </button>
      );
    }
  }
);
export const focusClasses = `focus-visible:outline-none focus-visible:ring focus-visible:ring-offset-2 focus-visible:ring-nad-ming`;
const baseClasses = `
  group
  font-sans font-bold tracking-wider
  text-center uppercase
  rounded-full 
  border-2
  transition
  ${focusClasses}
  disabled:cursor-not-allowed`;
const outlineClassName = `
  ${baseClasses}
  bg-white
  border-nad-blue text-nad-blue
  hover:bg-nad-blue hover:text-white hover:border-nad-white
  disabled:border-nad-alps-night-2 disabled:text-nad-alps-night-2 disabled:hover:bg-white
  data-[loading="true"]:bg-nad-blue data-[loading="true"]:text-white data-[loading="true"]:border-nad-blue data-[loading="true"]:hover:bg-nad-blue
  focus-visible:bg-nad-blue focus-visible:text-white
`;
const outlineAltClassName = `
  ${baseClasses}
  bg-white
  border-nad-scarlett text-nad-scarlett
  hover:bg-nad-scarlett hover:text-white
  disabled:opacity-50 disabled:hover:text-nad-scarlett disabled:hover:bg-white
  data-[loading="true"]:bg-nad-scarlett data-[loading="true"]:text-white data-[loading="true"]:border-nad-scarlett data-[loading="true"]:hover:bg-nad-scarlett data-[loading="true"]:opacity-100 data-[loading="true"]:hover:text-white
  focus-visible:bg-nad-scarlett focus-visible:text-white
`;
const outlineWhiteClassName = `
  ${baseClasses}
  border-nad-white text-nad-white
  hover:bg-nad-white hover:text-nad-blue
  disabled:border-nad-alps-night-2 disabled:text-nad-alps-night-2
  data-[loading="true"]:bg-nad-white data-[loading="true"]:text-nad-blue data-[loading="true"]:border-nad-white
  focus-visible:bg-nad-white focus-visible:text-nad-blue
`;
export const mainClasses = `
  ${baseClasses}
  text-white bg-nad-blue  border-nad-blue
  hover:bg-nad-ming hover:border-nad-ming
  disabled:bg-nad-alps-night-2 disabled:border-nad-alps-night-2
  data-[loading="true"]:bg-nad-ming data-[loading="true"]:border-nad-ming
  focus-visible:bg-nad-ming focus-visible:border-nad-ming
`;
export const linkClasses = `
  ${baseClasses}
  text-nad-blue
  hover:text-nad-ming
  border-transparent
  disabled:text-nad-alps-night-2
  focus-visible:text-nad-ming
`;
export const linkAltClasses = `
  ${baseClasses}
  text-nad-scarlett
  border-transparent
  hover:bg-nad-scarlett hover:text-white
`;
export const linkTinyClasses = `
  text-xs md:text-xs
  text-nad-ming
`;

export const widthNormalClasses = `w-full sm:w-fit lg:min-w-[150px]`;
export const widthMediumClasses = `w-full sm:w-fit sm:min-w-[200px] sm:max-w-full`;
export const widthLargeClasses = `w-full sm:w-fit sm:min-w-[320px] sm:max-w-full`;

export const sizeNormalClasses = 'text-s px-10 py-5';
export const sizeSmallClasses = 'text-s px-8 py-5 sm:py-2';
export const sizeExtraSmallClasses = 'text-xs px-12 py-4 md:py-1.5';

export const iconBaseClasses = `transition`;
export const iconMainClassesFill = `${iconBaseClasses} group-disabled:fill-nad-alps-night-2`;
export const iconOutlineClassesFill = `${iconBaseClasses} group-hover:fill-white group-focus-visible:fill-white group-disabled:fill-nad-alps-night-2`;
export const iconOutlineAltClassesFill = `${iconBaseClasses} group-hover:fill-white group-focus-visible:fill-white group-disabled:fill-nad-scarlett`;
export const iconOutlineWhiteClassesFill = `${iconBaseClasses} group-hover:fill-nad-blue group-focus-visible:fill-nad-blue group-disabled:fill-nad-scarlett`;
export const iconLinkClassesFill = `${iconBaseClasses} group-disabled:fill-nad-alps-night-2 group-hover:fill-nad-ming group-focus-visible:fill-nad-ming`;
export const iconLinkAltClassesFill = `${iconBaseClasses} group-hover:fill-nad-scarlett`;
export const iconMainClassesStroke = `${iconBaseClasses} group-disabled:stroke-nad-alps-night-2`;
export const iconOutlineClassesStroke = `${iconBaseClasses} group-hover:stroke-white group-focus-visible:stroke-white group-disabled:stroke-nad-alps-night-2`;
export const iconOutlineAltClassesStroke = `${iconBaseClasses} group-hover:stroke-white group-focus-visible:stroke-white group-disabled:stroke-nad-scarlett`;
export const iconOutlineWhiteClassesStroke = `${iconBaseClasses} group-hover:stroke-nad-blue group-focus-visible:stroke-nad-blue group-disabled:stroke-nad-scarlett`;
export const iconLinkClassesStroke = `${iconBaseClasses} group-hover:stroke-nad-ming group-focus-visible:stroke-nad-ming`;
export const iconLinkAltClassesStroke = `${iconBaseClasses} group-hover:stroke-white`;

Button.displayName = 'Button';
