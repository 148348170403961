import { useSession } from 'next-auth/react';
import { mutate } from 'swr';
import { ApiHook, useCreateSWREndpoint } from './use-swr-endpoint';
import { UserRole } from '@models/user-role';
import { CurrentUser } from '@models/user';
import { makeRequest } from '@fetch/helpers';
import { OrganizationType } from '@models/organization';

export const CURRENT_USER_URL = `/api/v3/current_user`;

export const isMoneyMover = (user: CurrentUser, country?: string) => {
  if (!country || country.toLowerCase() === 'ca') {
    if (!!user.roles.find((r) => r.type === 'cad_money_mover')) {
      return true;
    }
  }

  if (!country || country.toLowerCase() === 'us') {
    if (!!user.roles.find((r) => r.type === 'usd_money_mover')) {
      return true;
    }
  }

  return false;
};

export const isAdmin = (user: CurrentUser) => {
  return user.roles.find((r) => r.type === 'admin');
};

export const isTreasurer = (user: CurrentUser, orgID?: string) => {
  if (isAdmin(user)) {
    return true;
  }

  if (orgID) {
    return user.roles.find(
      (r) => r.type === 'treasurer' && r.organization.id === orgID
    );
  }
  return user.roles.find((r) => r.type === 'treasurer');
};

export const isFeaturedVideoAdmin = (user: CurrentUser) => {
  return user.roles.find((r) => r.type === 'featured_video_admin');
};

export const formatUserAddress = (user?: CurrentUser) => {
  const hasCity = user?.city;
  return user
    ? `${user.address || ''}\n${user.address2 ? `${user.address2}\n` : ''}${
        user.city || ''
      }${hasCity ? ',' : ''} ${user.state || ''} ${user.postal_code || ''}`
    : '';
};

export const useCurrentUser = (): ApiHook<CurrentUser> => {
  const { data: session, status: sessionStatus } = useSession();
  // non-breaking change for existing logged in users
  // where their session property does not have the `scope` property
  const shouldFetch =
    sessionStatus === 'authenticated'
      ? session.scope
        ? session.scope.includes('authenticated')
        : true
      : false;
  const response = useCreateSWREndpoint<CurrentUser>({
    url: CURRENT_USER_URL,
    shouldFetch,
  });
  // Todo: update based on feedback here https://adventistgiving.slack.com/archives/C03FCHY3Y0Y/p1697721372146829
  // or if API updates the `org_type` to lowercase.
  if (response.data) {
    response.data.roles = response.data.roles.map((r) => ({
      ...r,
      organization: {
        ...r.organization,
        org_type:
          r.organization.org_type?.toLocaleLowerCase() as OrganizationType,
      },
    }));
  }
  return {
    ...response,
    isLoading: sessionStatus === 'loading' || response.isLoading,
  };
};

export const sendEmailVerificationCode = (email: string) => {
  return makeRequest({
    method: 'POST',
    url: `/api/v3/current_user/email_addresses`,
    data: {
      email,
    },
  });
};

export const clearCache = () => {
  mutate(() => true, undefined, { revalidate: true });
};

export const sortRolesByOrgName = (roles: UserRole[]) => {
  return roles.sort((a, b) => {
    return a.organization.name > b.organization.name
      ? 1
      : a.organization.name < b.organization.name
      ? -1
      : 0;
  });
};
