import { Props, fillColors } from '.';

export const IconArrowDown = ({
  width = 10,
  height = 10,
  color = 'nad-blue',
  className = '',
  ...props
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${fillColors[color]} ${className}`}
      {...props}
    >
      <g clip-path="url(#clip0_9453_203670)">
        <path d="M9.90991 5.00005L8.49991 3.62005L5.99991 6.12005L5.99991 0.0500486L3.99991 0.0500485L3.99991 6.12005L1.49991 3.62005L0.0899132 5.00005L4.99991 10L9.90991 5.00005Z" />
      </g>
      <defs>
        <clipPath id="clip0_9453_203670">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(10) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
