import { fillColors, Props } from '.';

export const IconSortHeader = ({
  width = 10,
  height = 13,
  className = '',
  color = 'nad-blue',
  ...props
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 13"
      className={`${fillColors[color]} ${className}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8856_196356)">
        <path d="M2.66846 3.25737L3.6248 4.1564L5.32045 2.52771L5.32045 6.48218L6.67697 6.48218L6.67697 2.52771L8.37262 4.1564L9.32896 3.25737L5.99871 -1.82713e-05L2.66846 3.25737Z" />
      </g>
      <g clip-path="url(#clip1_8856_196356)">
        <path d="M6.72154 9.22929L5.76519 8.33025L4.06955 9.95894L4.06955 5.97191L2.71303 5.97191L2.71303 9.95894L1.01738 8.33025L0.061035 9.22929L3.39129 12.4541L6.72154 9.22929Z" />
      </g>
      <defs>
        <clipPath id="clip0_8856_196356">
          <rect
            width="6.51477"
            height="6.78259"
            fill="white"
            transform="translate(2.60742 6.51477) rotate(-90)"
          />
        </clipPath>
        <clipPath id="clip1_8856_196356">
          <rect
            width="6.51477"
            height="6.78259"
            fill="white"
            transform="translate(0 12.4867) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
